.dashboard-menu .ant-radio-button-wrapper-checked {
  /* border-color: #dbeafe !important; */
  background-color: #dbeafe !important;
  color: black;
  font-size: 14px !important;
}
.dashboard-menu.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: rgb(0, 0, 0);
  background: #2b3ee5;
  border-color: #2b3ee5;
}
.container {
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 0px !important;
}
.ant-menu-light {
  background: none !important;
}
thead > tr > th {
  background-color: #dbeafe !important;
}
.ant-table-summary > tr > td,
.ant-table-summary > tr > th {
  background-color: #a78d8d1a;
}
.ant-table-cell {
  word-break: break-word !important;
  padding: 12px 12px !important;
  text-align: center !important;
}
.line-tooltip {
  margin: 10px;
  width: 180px;
  border: 1px solid #473f81;
  border-radius: 6px;
  background: linear-gradient(
    180deg,
    rgba(227, 223, 255, 0.6) 0%,
    rgba(210, 204, 255, 0.52) 100%
  );
  padding: 10px;
  backdrop-filter: blur(1.5px);
}
.qc-tooltip {
  margin: 10px;
  width: 250px;
  border: 1px solid #473f81;
  border-radius: 6px;
  background: linear-gradient(
    180deg,
    rgba(223, 241, 255, 0.6) 0%,
    rgba(204, 213, 255, 0.52) 100%
  );
  padding: 10px;
  backdrop-filter: blur(1.5px);
}
.inspection-tooltip {
  padding: 10px;
  border: 1px solid #473f81;
  width: 200px;
  /* height: 70px; */
  left: 301.5px;
  top: 124px;
  background: linear-gradient(
    180deg,
    rgba(223, 249, 255, 0.6) 0%,
    rgba(204, 243, 255, 0.52) 100%
  );
  backdrop-filter: blur(1.5px);
  border-radius: 6px;
}

.fortnight .ant-table-thead > tr > th {
  border: 0.5px solid rgb(174, 174, 174) !important;
}
.cardStyles {
  border: 1px solid #98a2b3;
  height: 500px;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 3px 0px #1018281a;
}
.ant-card .ant-card-head {
  border-bottom: 1px solid #98a2b3;
}
.tooltip {
  font-weight: 600;
  font-size: 16px;
}
