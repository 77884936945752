.menu-logo,
.navigation-dropdown,
.memu-avatar,
.menu-organization .org-change {
  cursor: pointer;
}
.ant-layout-header,
.layout-header {
  background: #bfdbfe;
}

@media only screen and (max-width: 550px) {
  .ant-layout-header {
    padding: 0px 18px !important;
  }
}

.menu-dropdown {
  text-align: right;
  cursor: pointer;
}

.menu {
  min-width: 200px;
}

.home {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  text-align: right;
}

.mobileItemName {
  font-size: 11px;
}
.grid-container {
  display: grid !important;
  grid-template-columns: 18% auto 8%;
  grid-template-rows: auto;
  grid-gap: 1rem;
  width: 100%;
  align-items: center;
}

.grid-container:nth-child(2) {
  align-self: center !important;
}
.search {
  width: 100%;
}

.main-school-search > span > span {
  display: none;
}
.main-school-search > span > input {
  border-radius: 1rem !important;
}
.ant-layout-footer {
  padding: 0.5rem 50px !important;
}

.gap-16 {
  gap: 16px !important;
}
