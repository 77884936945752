.desktop-logo {
  transition: color 0.3s;
  width: 220px !important;
  height: auto !important;
}

@media only screen and (min-device-width: 960px) and (max-device-width: 1024px) {
  .desktop-logo {
    width: 200px !important;
  }
}
