.login {
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  /* background-color: #eff9ff; */
  background-size: cover;
  color: #bbc4cc;
  background-image: url("../../assets/images/Frame.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;

  padding-top: 100px !important;
}
