.layout-header {
  background-color: #bfdbfe !important;
}
.layout-body {
  /* background-color: #eff4ff; */
  background-color: #fbf9ff;
  min-height: calc(100vh - 140px) !important;
  min-height: -o-calc(100vh - 140px) !important;
  min-height: -webkit-calc(100vh - 140px) !important;
  min-height: -moz-calc(100vh - 140px) !important;
  padding: 20px 0px !important;
}

.footer {
  background-color: #fffef5;
  /* background-color: #f1f1f1 !important; */
  text-align: center;
}
